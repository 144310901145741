export const columns = [
  {
    title: 'Lote',
    dataKey: 'numero',
  },
  {
    title: 'Descrição',
    dataKey: 'nome',
  },
  {
    title: 'Status',
    dataKey: 'status',
  },
  {
    title: 'Quantidade de lances',
    dataKey: 'lances',
  },
  {
    title: 'Lance atual/final',
    dataKey: 'lance_atual',
  },
  {
    title: 'Lance atual/final + Comissão + Txs',
    dataKey: 'lance_atual_5',
  },
  {
    title: 'Descrição',
    dataKey: 'descricao',
    contentIsReactComponent: true,
  },
  {
    title: 'Opções',
    dataKey: 'actions',
    contentIsReactComponent: true,
    align: 'right',
  },
];
